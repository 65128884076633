.page-dashboard {
  background-color: var(--color-grey-100);
  min-height: 100%;
}

.dashboard {
  // padding: var(--cnt-margin);
}
.dashboard__header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;

  button {
    margin-right: -10px;
    height: 50px;
    width: 50px;
  }

  svg {
  }
}
.dashboard-section {
  // margin-bottom: 50px;
}
.dashboard-section__header {
  border-bottom: 2px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  > h2 {
    font-size: 20px;
    font-weight: 600;
  }
}
.dashboard-section__content {
  padding-top: 25px;
}

// -- Dashboar Statistics --------------------------------------------------- //
.dashboard {
  padding-bottom: 30px;
  .tokens-panel {
    margin-bottom: 30px;
  }
}

// -----------------------------------------------------------------------------
.works__section {
}
.works-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  padding: var(--cnt-margin);
  @include container-reset();

  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  // display: flex;

  // scrollbar
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow: -moz-scrollbars-none; // Firefox
  scrollbar-width: none; /* Firefox 64 */
  cursor: grab;
  &.use-mouse {
    scroll-behavior: auto;
  }
  &.is-moving {
    * {
      pointer-events: none;
    }
  }

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  li {
    cursor: pointer;
    flex-shrink: 0;
    aspect-ratio: 1/1;

    box-shadow: 0 -1px 18px 0 rgba(0, 0, 0, 0.08);
    border-radius: 15.99px;
    background-color: white;

    width: 100px;
    overflow: hidden;

    &:last-child {
      margin-right: 50px;
    }
  }
  figure {
    padding: 0;
    margin: 0;
    width: 100%;
    img {
      display: block;

      width: 100%;
      height: 100%;
      object-fit: contain;
      // display: none;
    }
  }
}

.works-list__add {
  display: flex;
  justify-content: center;
  align-items: center;
}
// -- easter eggs ----------------------------------- //
.collected-list {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid grey;
    img {
      width: 100px;
      height: auto;
    }
    span {
      margin-left: 10px;
    }
  }
}
