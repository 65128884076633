.tokens-panel {
  //   overflow: hidden;
  //   background-color: #ced5ff;
  //   border-radius: 25px;
  color: var(--color-blue);
  box-shadow: 0 -1px 17px 0 rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  overflow: hidden;
}

.tokens-panel__header {
  position: relative;
  background-color: #ced5ff;

  padding: 10px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 10px;
  z-index: 1;

  h3 {
    @include fnt-heading-200();
    margin-bottom: 0.2em;
  }

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: var(--color-blue);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2;
  }
}

.tokens-panel__content {
  overflow: scroll;
  max-height: 60vh;
  background-color: #c1caff;
  position: relative;

  .tokens-panel-content__inner {
    @include container();
    padding-top: 6px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
  }

  li {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 11px;
    padding-bottom: 11px;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: currentColor;
      position: absolute;
      bottom: 0;
      opacity: 0.2;
    }
    &:last-child:after {
      display: none;
    }
    figure {
      flex-shrink: 0;
      width: 80px;
      position: relative;
      img {
        position: absolute;
        inset: 0;
      }
    }
  }
}
.tokens-panel__toggle {
  position: relative;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c1caff;

  &:before {
    pointer-events: none;
    content: '';
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;

    transform: translateY(-100%);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #c1caff 100%);
  }
}

.tokens-panel.no-tokens {
  .tokens-panel__header:after {
    display: none;
  }
  .tokens-panel__toggle {
    display: none;
  }
}

.stat-label {
  display: inline-block;
  background-color: var(--color-blue);
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  line-height: 1;

  font-size: 20px;

  padding: 4px;
  padding-bottom: 7px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  // min-width: 2em;
  border-radius: 5px;

  &.size--small {
    font-size: 15px;
    padding-top: 2px;
    padding-bottom: 5px;
  }
}

// stats-panel --------------------------------------------------- //
.stats-panel {
  background: #ffffff;
  box-shadow: 0 -1px 17px 0 rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  .stats-panel__row {
    border-bottom: 2px solid var(--color-grey-200);

    &:last-child {
      border-bottom: none;
    }
  }
}

.stats-panel__row {
  padding: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  > div {
    min-width: 60px;
    .stat-label {
      display: inline-block;
    }
  }

  p {
    margin-bottom: 2px;
    line-height: 1.1;
  }
}

// -- Progress bar ---------------------------------------------------- //
.progress-bar {
  height: 12px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    display: block;
    background-color: var(--color-blue);
    opacity: 0.2;
  }

  .progress-bar__fill {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--color-blue);
    border-radius: 8px;
  }
}
