// @import "~susy/sass/susy";
// @import "~susy/sass/plugins/svg-grid";

/** -- 1. Abstracts -------------------------------------------------------------- */
// @import "./abstracts/functions";
// @import "./abstracts/mixins";
// @import "./abstracts/variables";

/** -- 2. Base ------------------------------------------------------------------- */
// @import "./base/grid";
@import './base/resets';
@import './base/base';
@import './base/typography';

/** -- 3a. Debug ----------------------------------------------------------------- */
// @import "./base/debug";
// @import "~pesticide/sass/pesticide.scss";
// pages
@import './components/page-introduction';
@import './components/page-dashboard';

/** -- 5. Layout ----------------------------------------------------------------- */
@import './components/generator';
@import './components/generator-toolbar';
@import './components/generator-worklines';

@import './components/dashboard';
@import './components/easter-egg-dialog';
@import './components/loader';
@import './components/toolbar';
@import './components/popup-menu';
@import './components/modal';
@import './components/overlay';
@import './components/color-list';
@import './components/dialog';
@import './components/tokens';
@import './components/tokens-panel';
@import './components/icon-button';
@import './components/select-content';
@import './components/sentences-renderer';
// ---
@import './layouts/generator-overlay';

// -- NEW CODE ----------------------------------------------------------------------- //
@import './base/utilities';

// work around the react wrappers
#root,
.App {
  position: absolute;
  inset: 0;
  // height: 100vh;
}
#root {
}

// make the view the full browser heigh
html,
body {
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  touch-action: none;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  font-size: 16px;
}

button {
  cursor: pointer;
}
// mechanism to convert the data-attribute to a color variable
// uses the $colors variable from the base.scss file
@each $key, $colorSet in $colors {
  .App[data-work-color='#{$key}'] {
    --color-app: #{nth($colorSet, 1)};
    // --work-color: #{nth($colorSet, 1)};
  }
}

// on desktop computers the app should be shown as a mobile screen size
@media (orientation: landscape) {
  // display: none;

  body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5vh;
    background-color: var(--color-grey-300);
  }
  .App,
  #root {
    height: 100vh;
    position: relative;
    max-height: 800px;
  }
  #root {
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    background-color: white;
    background: #ffffff;
    box-shadow: 0 -1px 18px 0 rgba(0, 0, 0, 0.08);
    border-radius: 15.99px;
  }
  .App {
    position: relative;
    aspect-ratio: 828 / 1392;
    position: relative;
    width: 100%;
  }
}

main {
  overscroll-behavior-y: contain;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// -- OLD CODE ----------------------------------------------------------------------- //

.main__body {
  flex: 1;
  // display: flex;
  // width: 100%;
  overflow-y: auto;
  // overflow: auto;
  // > * {
  //   flex: 1;
  //   width: 100%;
  // }

  // overscroll-behavior: contain;
  // scrollbar
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow: -moz-scrollbars-none; // Firefox
  scrollbar-width: none; /* Firefox 64 */

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

#dialog-root,
#overlay-root {
  pointer-events: none;
  position: absolute;
  top: 0;
  inset: 0;
  z-index: 100;
}

#modal-root {
  position: relative;
  z-index: 100;
}
#popup-root {
  position: absolute;
  top: 0;
  inset: 0;
  z-index: 10;
  pointer-events: none;
}
// .main__body {
//   flex: 1;
// }

nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

// Modal --------------------------------------------------

.choice-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: flex-start;

  &.use-mouse {
    cursor: grab;
    overflow: hidden;

    @include container-reset();
    padding-left: var(--cnt-margin);
    padding-right: var(--cnt-margin);

    &.is-moving {
      * {
        pointer-events: none;
      }
    }
  }
}

.choice-list {
  button {
    --button-color: var(--color-grey);
    --button-text-color: var(--color-app);

    &.is-active {
      --button-color: var(--color-app);
      --button-text-color: white;
    }
  }
}

.pill-button {
  --button-color: var(--color-grey);
  --button-text-color: var(--color-app);

  user-select: none;
  text-transform: lowercase;

  outline: none;
  background: none;
  appearance: none;
  border-radius: 0;
  border: none;

  color: currentColor;
  font-size: 18px;
  line-height: 1;

  cursor: pointer;
  background: none;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 12px;
  padding-bottom: 16px;
  border-radius: 9999px;
  white-space: nowrap;

  color: var(--button-text-color);
  background: var(--button-color);

  transition: background-color 250ms ease, color 250ms ease;
  // font-size: 16px;

  &.is-primary {
    --button-color: var(--color-app, black);
    --button-text-color: white;
  }

  &.is-black {
    --button-color: black;
    --button-text-color: white;
  }
}

.pill-button.pill-button--large {
  font-size: 23px;

  padding-top: 14px;
  padding-bottom: 21px;
  padding-left: 33px;
  padding-right: 33px;

  // padding: 15px;
  // padding-left: 30px;
  // padding-right: 30px;
}

// ----------------------------------------------------------- //
// .worklines-test {
//   // border: 1px solid red;

//   font-size: 20px;
//   .line {
//     display: contents;

//     &:nth-child(odd) {
//       > * {
//         background-color: grey;
//       }
//     }
//     &.is-active {
//       > * {
//         background-color: red;
//       }
//     }
//   }

//   .line__part {
//     display: inline-block;
//     // padding-top: 5px;
//     // padding-bottom: 5px;
//   }
// }

//

.add-content-swiper {
  // .swiper-wrapper {
  //   margin-top: 10px;
  // }
  .swiper-pagination {
    // position: absolute;
    top: 0;
    bottom: auto !important;
    // left: 10px !important;
    // right: 10px !important;
    // width: auto !important;

    .swiper-pagination-bullet-active-main {
      --swiper-theme-color: var(--color-app);
    }
  }
  // .swiper-pagination-progressbar {
  //   background-color: var(--color-grey);
  // }
}

// body > img {
//   border: 1px solid red;
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   object-fit: contain;
//   z-index: 1000;
//   height: 10%;
// }

.loader-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;

  display: flex;
  align-items: center;
  backdrop-filter: grayscale(1);

  justify-content: center;
  .loader-layer__backdrop {
    position: absolute;
    inset: 0;
    background-color: white;
    opacity: 0.8;
  }
}

.loader-layer__container {
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 16px 0 rgba(167, 167, 167, 0.5);
  margin-bottom: 100px;

  path {
    fill: var(--color-app);
  }
}

//
