.easter-egg-dialog {
  //   background-color: Red;
  padding: 10px;
  padding-top: 50px;
  iframe {
    aspect-ratio: 16/9;
    width: 100%;
    height: auto;
  }
}
