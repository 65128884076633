// -- Dialog ------------------------------------------
.dialog-wrapper {
  position: absolute;
  inset: 0;

  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;

  padding: var(--cnt-margin);

  .dialog-wrapper__backdrop {
    position: absolute;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.83);
  }
}

.dialog {
  background-color: white;
  width: Min(100%, 340px);

  position: relative;
  border-radius: 13px;
  padding: 22px;
  box-shadow: 0 0 16px 0 rgba(167, 167, 167, 0.5);

  color: var(--color-app);

  .dialog__wrapper {
    position: relative;
  }

  .dialog__header {
    display: flex;
    justify-content: flex-end;
  }
  .dialog__content {
    @include fnt-body-700();
    padding-bottom: 20px;
  }
  .dialog__footer {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
  }
}
