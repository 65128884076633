.generator-worklines {
  // min-height: 100%;

  --sentence-pad-y: 5px;
  --sentence-pad-x: 10px;
  --sentence-space: 5px;

  font-size: 21px;
  margin-bottom: 100px;

  .line {
    display: contents;
    cursor: pointer;
    user-select: none;

    // $colors: map-get($colors, var(--work-color));

    // @for $i from 1 through length($colors) {
    //   &:nth-child(#{length($colors)}n + #{$i}) {
    //     .line__part {
    //       background-color: nth($colors, $i);
    //     }
    //   }
    // }

    .line__part {
      position: relative;
      color: white;
      transition: background-color 0.5s ease;

      &:after {
        transition: background-color 0.5s ease;
      }
    }
    &.is-active {
      .line__part {
        background-color: black !important;
        color: white;
      }
    }
    &.is-new {
      .line__part:after,
      .line__part {
        background-color: black !important;
        color: white;
      }
    }
  }

  .line__content {
    display: contents;
  }

  .line-controls {
    width: 0px;
    height: 40px;
    // border: 1px solid red;
    display: inline;
    position: relative;
    z-index: 1;

    button {
      background: none;
      border: none;
      border: 2px solid black;
      background-color: white;
      border-radius: 999px;
      width: 28px;
      height: 28px;
      padding: 0;
      margin: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        position: absolute;
        display: block;
        width: 28px;
        height: 28px;
      }
    }

    .line-controls__inner {
      position: absolute;
      display: inline-flex;
      flex-direction: row;
      height: 39px;
      align-items: center;
      gap: 5px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .line-controls--before {
    .line-controls__inner {
      right: 0px;
    }
  }

  .line-controls--after {
    .line-controls__inner {
      left: 0px;
    }
  }

  .line__part {
    display: inline-block;

    vertical-align: top;

    // width: 100px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;

    > span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.line .line__wrapper {
  display: contents;
}

.line > span {
  display: inline-block;
  // padding-right: 0.2em;
  // border: 1px solid green;
}
// .line

// -- line colors
// $colors: map-get($colors, var(--work-color));

@each $key, $colorSet in $colors {
  .App[data-work-color='#{$key}'] {
    .generator-worklines .line {
      @for $i from 1 through length($colorSet) {
        &:nth-child(#{length($colorSet)}n + #{$i}) {
          .line__part,
          .line__part:after {
            background-color: nth($colorSet, $i);
          }
        }
      }
    }
  }
  //   &:nth-child(#{length($colors)}n + #{$i}) {
  //     .line__part {
  //       background-color: nth($colors, $i);
  //     }
  //   }
}

// .App[data-work-color] {
//   .generator-worklines .line .line__part {
//     background-color: blue;
//   }
// }

// solution for adding padding on both the unsplitted and the splitted
// lines. In javascript runs a function which add classes to the pre-splitted
// lines to simulate the padding.

// -- pre split ------------------------------------------
.line:not(.is-splitted) {
  > span {
    &:first {
      padding-left: var(--sentence-pad-x);
    }
    // border: 1px solid red;

    padding-right: var(--sentence-space);
    // padding-right: 10px;
    // padding-right: 0.25em;
  }

  // helps postitioning the pre-split token
  .token {
    > div {
      position: relative;
    }
  }
  // padding-left: 10px;
  // padding-right: 10px;
  .left-padding {
    background-color: green;
    padding-left: var(--sentence-pad-x);
  }
  .right-padding {
    background-color: purple;
    padding-right: var(--sentence-pad-x) !important;
  }
}

// setup for calculating the right widths, this works with the
// system build in react wich splits the text into sections

// -- after split ------------------------------------------
// spaces are replaced by custom padding
.line.is-splitted {
  .line__part > span > span {
    padding-right: var(--sentence-space);
    &:last-child {
      padding-right: 0;
    }
  }

  .line__part {
    // padding-left: var(--sentence-pad-x);
    // padding-right: 0;
    // padding-right: 5px;
  }

  .line__part {
    padding-top: 2px;
    padding-bottom: 5px;
    //   // padding-top: var(--sentence-pad-y);
    //   // padding-bottom: var(--sentence-pad-y);
    //   padding-left: var(--sentence-space);
    //   padding-right: 0;
    //   // padding-right: 5px;
  }

  .line__part {
    padding-left: var(--sentence-pad-x);
    padding-right: var(--sentence-pad-x);
  }

  .line__part--token {
    padding: 0;
  }
}

.line__part.extend {
  // border: 1px solid red;
  &:after {
    content: '';
    position: absolute;
    width: 200px;
    height: 100%;
    right: 0;
    top: 0;
    opacity: 1;
    // background-color: red;
    transform: translateX(100%);
    transition: opacity 250ms ease;
  }

  &.hide-pseudo {
    &:after {
      opacity: 0;
    }
  }
}

.line__part.last {
  background-color: red !important;
}

.line.is-active {
  .line__part:after {
    background-color: black !important;
  }
  .line__wrapper .line__part:last-child.extend {
    &:after {
      display: none;
    }
  }
}
