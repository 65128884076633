// -- FONTS --------------------------------------------------------------- //
$path-fonts: '../../assets/fonts/';

@mixin fontface($name, $font, $weight) {
  @font-face {
    font-family: #{$name};
    src: url('#{$path-fonts}#{$font}.woff') format('woff'), url('#{$path-fonts}#{$font}.woff2') format('woff2');
    font-weight: $weight;
    font-style: normal;
  }
}

@include fontface('Jungka', 'Jungka-Regular', 400);
@include fontface('Jungka', 'Jungka-Medium', 600);
@include fontface('Jungka', 'Jungka-Bold', 700);

html {
  font-family: 'Jungka';
}

body {
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  // fix the ugly aliasing in firefox
  -moz-osx-font-smoothing: grayscale;
}

// -- FONTS --------------------------------------------------------------- //

@mixin fnt-body-700() {
  font-size: 26px;
  line-height: 1.1;
}

// -- Headings ---------------------------------------- //
// 400: small
// 500: medium
// 600: large
// 700: x-large

@mixin fnt-heading-100() {
  font-size: 18px;
  line-height: 1.1;
  font-weight: 600;
}

@mixin fnt-heading-200() {
  font-size: 20px;
  line-height: 1.1;

  font-weight: 600;
}

@mixin fnt-heading-300() {
  font-size: 26px;
  line-height: 1.1;
  font-weight: 600;
}

@mixin fnt-heading-500() {
  line-height: 1.1;

  // line-height: 1.0454;
  font-size: 35px;
  font-weight: 600;
  // font-size: rem-calc(28);

  // @media (--view-s-up) {
  // 	font-size: rem-calc(32);
  // }
  // @media (--view-xl-up) {
  // 	font-size: rem-calc(44);
  // }
}
