.overlay {
  position: absolute;
  inset: 0;
  pointer-events: all;
}

.overlay-panel__body {
  position: relative;
  flex: 1;
  overflow-y: auto;
}

// -- Overlay panel style ----------------------------------------------------------------------- //
.overlay--panel {
  .overlay__backdrop {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    backdrop-filter: grayscale(1);
  }

  .overlay__panel {
    position: absolute;
    inset: 0;
    top: 5vh;
    background-color: white;
    box-shadow: 0 0 16px 0 rgba(167, 167, 167, 0.5);
  }

  .overlay-panel {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    overflow: hidden;
  }

  .overlay-panel__header {
    padding-left: var(--cnt-margin);
    padding-right: var(--cnt-margin);
    padding-top: 16px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    color: var(--color-app);

    border-bottom: 2px solid var(--color-grey-200);

    h3 {
      @include fnt-heading-300();
    }
    button {
      margin-left: auto;
    }
  }

  .overlay-panel__body {
    padding-top: var(--cnt-margin);
  }
}

// -- Overlay full style --------------------------------------------------------------- //
.overlay--full {
  // border: 1px solid red;

  .overlay__panel {
    position: absolute;
    inset: 0;
    background-color: white;
  }

  .overlay-panel__body {
    position: relative;
    flex: 1;
    overflow-y: auto;
    height: 100%;
  }
}

// ---------------------------------
.overlay--add-content {
  .overlay-panel__header {
    border-bottom: none;
    padding-bottom: 0px;
  }
}
// -- Overlay page text --------------------------------------------------------------- //
.overlay--default-text {
  .overlay-panel__header {
    color: black;
  }
}

// -- Overlay token collect ----------------------------------------------------------- //
.overlay--token-collect {
  &.is-collecting {
    .overlay__panel {
      top: 80px;
    }
  }

  .awards-splash {
    transform: translate3d(0, 0, 0);
    position: absolute;
    inset: 0;
    will-change: all;

    > * {
      will-change: all;
    }
    // background-color: red;
  }

  .awards-header {
    position: absolute;
    top: var(--cnt-margin);
    left: var(--cnt-margin);
    perspective: 200px;
    // width: 200px;
    // height: 200px;
    // transform: perspective(200);
  }
  .award-badge {
    background-color: var(--color-app);
    display: inline-block;
    color: white;
    @include fnt-heading-300();
    padding-bottom: 8px;
    padding-top: 2px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    // animation: badgeRotate 4s ease-out infinite;
    backface-visibility: hidden;
    transform-style: preserve-3d;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: rotateY(180deg);
      backface-visibility: hidden;
      border-radius: 8px;
      background-color: var(--color-app);
    }
  }
}

.splash-node {
  background-color: var(--color-app);
  position: absolute;
  top: var(--cnt-margin);
  left: var(--cnt-margin);
  color: white;
  @include fnt-heading-300();
  padding-bottom: 8px;
  padding-top: 2px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px;
}

.award-badge--clone {
  // animation: badgeRotate 4s ease-out infinite;

  &:after {
  }
}
@keyframes badgeRotate {
  0% {
    transform: perspective(200px) rotateY(0deg);
  }
  50% {
    transform: perspective(200px) rotateY(360deg * 6);
  }
  100% {
  }
}

.page-colofon {
  .logo-footer {
    margin-top: 20px;
    width: 200px;
    margin-left: -30px;

    svg {
    }
  }
}

.richtext {
  p {
    line-height: 1.3;
  }
  p + p {
    margin-top: 1.5em;
  }

  // p.intro {
  //   font-size: 18px;
  //   line-height: 1.2;
  // }

  h4 {
    margin-top: 1em;
    font-weight: 600;
    margin-bottom: 0.2em;
  }

  a {
    color: var(--color-app);
    text-decoration: none;
  }

  ul {
    padding: 0;
    margin: 0;
  }
}
