// .color-brown {
//   --theme-color: #6e360f;
// }
// .color-purple {
//   --theme-color: #860efe;
// }
.color-blue {
  --theme-color: #0026ff;
}
// .color-orange {
//   --theme-color: #ff7400;
// }
// .color-red {
//   --theme-color: #ff0067;
// }

.color-brown {
  --theme-color: #6e360f;
}
.color-navy {
  --theme-color: #0c4266;
}
.color-ocean {
  --theme-color: #048fb8;
}
.color-purple {
  --theme-color: #934fd6;
}
.color-orange {
  --theme-color: #e77107;
}
.color-red {
  --theme-color: #eb005f;
}
.color-green {
  --theme-color: #06b77b;
}

.color-list {
  padding: 0;
  margin: 0;
  list-style-type: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.color-list__sample {
  button {
    padding: 0;
    display: block;
    border: none;
    width: 35px;
    aspect-ratio: 1/1;
    border-radius: 999px;

    background-color: var(--theme-color);
  }

  &.is-active {
    position: relative;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: '';
        display: block;
        position: relative;
        width: 10px;
        height: 10px;
        background-color: white;
        border-radius: 999px;
      }
    }
  }
}
