.generator-overlay {
  display: flex;
  flex-direction: column;
  height: 100%;

  .generator-overlay__header {
    padding-top: 50px;
    padding-bottom: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
    h1 {
      @include fnt-heading-500();
      max-width: 316px;
    }
  }
  .generator-overlay__content {
    flex: 1;
    margin-bottom: 10px;
  }
  .generator-overlay__footer {
    padding-top: var(--cnt-margin);

    padding-bottom: 50px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  }
}

.category-group {
  h3 {
    width: 100%;
    margin-left: 20px;
    // text-align: center;
    @include fnt-heading-100();
    margin-bottom: 9px;
  }
  margin-bottom: 20px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
}

.category-group__choices {
  overflow: scroll;

  padding: var(--cnt-margin);
  padding-top: 0;
  padding-bottom: 0;
  //   padding-bottom: 20px;
  //   padding-right: 20px;
  margin-top: 0px;

  @include container-reset(); // scrollbar
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow: -moz-scrollbars-none; // Firefox
  scrollbar-width: none; /* Firefox 64 */

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}
