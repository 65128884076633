// -- Easter eggs --------------------------------------------------------- //
.token {
  --token-width: 30px;
  // background-color: none;
  // padding: 0;
  // margin: 0;
  // background: none;
  // display: inline-block;
  // width: 20px;
  // > div {
  //   background-color: black;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   width: 20px;
  //   bottom: 0;
  // }
  // pointer-events: all;
  // ---

  border: none;
  background: none;

  width: var(--token-width);
  height: 28px; // hmm kinda sketchy
  padding: 0;
  margin: 0;
  display: inline-block;
  overflow: hidden;

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    width: var(--token-width);
    // background-color: rgba(0, 0, 0, 0.05);
    // background-color: red;
  }
  svg {
    height: 100%;
    // animation: pulse 1s infinite;
  }

  // @keyframes pulse {
  //   0% {
  //     transform: scale(0.85);
  //   }

  //   70% {
  //     transform: scale(1);
  //   }

  //   100% {
  //     transform: scale(0.85);
  //   }
  // }
  // --

  // background-color: black;
  // z-index: 1000;
  // // display: inline-block;
  // // background-color: red;
  // // width: 20px;
  // // height: 20px;
  // position: relative;
  // display: inline-flex;
  // justify-content: center;
  // align-items: center;
  // padding-left: 0.25em;
  // // margin-left: 10px;
  // padding-right: 0.25em;
  // border: 1px solid red;
  // width: 20px;
  // background: none;
  // height: 10px;
  // &:before {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 2px;
  //   bottom: -10px;
  //   background-color: currentColor;
  // }

  // &:after {
  //   content: '';
  //   display: block;
  //   width: 10px;
  //   height: 10px;
  //   background-color: currentColor;
  //   transform: rotate(45deg);
  //   transform-origin: center center;
  //   animation: easterEgg 3s infinite;
  // }

  // @keyframes easterEgg {
  //   from {
  //     transform: rotate(0deg);
  //   }
  //   to {
  //     transform: rotate(360deg);
  //   }
  // }
  // &:after {
  //   content: "";
  //   display: inline-block;
  //   border: 1px solid yellow;
  //   width: 10px;
  //   height: 10px;
  // }

  // &.is-discovered {
  //   &:after {
  //     background-color: yellow;
  //   }
  // }
}

.token.is-collected {
  opacity: 0.5;
  svg {
    animation: none;
  }
}

// -- Token Overlay ------------------------------------------------------------- //
.token-overlay {
  // padding-top: var(--cnt-margin);
  color: var(--color-app);
  @include container();

  .token-overlay__embed {
    position: relative;
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    margin-bottom: 15px;
  }

  .token-overlay__loader {
    opacity: 0;
    position: absolute;
    inset: 0;
    bottom: -2px;
    background-color: var(--color-grey-100);
    transition: opacity 250ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    div {
      width: 40px;
      height: 40px;

      svg {
        path {
          fill: var(--color-app);
        }
      }
    }
    // span {
    //   display: block;
    //   background-color: var(--color-app);
    //   width: 30px;
    //   height: 30px;
    //   // animation: spin 2s linear infinite;
    // }

    // @keyframes spin {
    //   0% {
    //     transform: rotate(0deg);
    //   }
    //   100% {
    //     transform: rotate(360deg);
    //   }
    // }
  }

  .label {
    display: inline-block;
    font-size: 15px;
    line-height: 1;
    background-color: var(--color-app);
    color: white;
    padding-bottom: 10px;
    padding-top: 7px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 7px;
    margin-bottom: 15px;
  }

  h2 {
    @include fnt-heading-300();
    // font-weight: 400;
  }
}

.token-overlay.is-loading {
  .token-overlay__loader {
    opacity: 1;
  }
  .token-overlay__embed {
    iframe {
      opacity: 0;
    }
  }
}
