.sentences-renderer {
  // hide the sentences render for the user
  transform: translateX(-99999px);
  opacity: 0;

  //
  position: absolute;
  top: 0;
  background-color: white;
  width: 500px;
  pointer-events: none;
  font-size: 21px;
  // border: 1px solid red;

  .sentences-renderer__inner {
    // extra padding for the export

    padding-bottom: 2px;
  }
  .ververs-logo {
    > span {
      display: inline-block;
      color: white;
      background-color: black;
      font-weight: 700;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 2px;
      padding-bottom: 5px;
    }

    > figure {
      // background-color: red;
      display: inline-block;

      margin-left: 5px;
      margin-right: 5px;
      height: 25px;
      aspect-ratio: 1/1;
    }

    // figure {
    //   border: 1px solid red;
    // }
  }
}
