.page-introduction {
  height: 100%;
  .swiper-slide {
    // width: 100% !important;
    overflow-y: auto;
  }

  .help-swiper {
    height: 100%;
  }
  .swiper {
    height: 100%;
  }

  .help-swiper__controls {
    position: absolute;
    bottom: 30px !important;
    right: 30px;
    z-index: 1;
  }
  .swiper-pagination {
    bottom: 40px !important;
  }
}

.help-list {
  section {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-bottom: 30px;
    align-items: top;

    figure {
      position: relative;
      padding: 0;
      margin: 0;
      flex-shrink: 0;
      aspect-ratio: 1/1;
      background-color: white;
      border-radius: 5px;
      overflow: hidden;
      width: 30%;
      overflow: hidden;

      box-shadow: 0 -1px 18px 0 rgba(0, 0, 0, 0.1);
      // this fixes the rounded corners video issue on safari
      // -webkit-mask-image: -webkit-radial-gradient(white, black);
      // isolation: isolate;
      // transform: translateZ(0);
      z-index: 0;
      video {
        position: absolute;

        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    p {
      margin: 0;
    }
  }
  section:nth-child(even) {
    flex-direction: row-reverse;
  }
}

.help-slide {
  @include container();
  height: 100%;
  display: flex;
  flex-direction: column;
  h2 {
    @include fnt-heading-500();
  }

  .help-slide__content {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 13vh;
  }
}

.help-start {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.help-slide__header {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
}
// .page-introduction {

//   padding: 30px;
//   display: flex;
//   justify-content: space-around;
//   flex-direction: column;
// }

// .page-introduction__footer {
//   text-align: center;
// }

// .features-list {
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// }

// .features-list__item {
//   display: flex;
//   gap: 20px;
//   figure {
//     flex-shrink: 0;
//     padding: 0;
//     margin: 0;
//     display: block;
//     width: 100px;
//     aspect-ratio: 1/1;
//     background-color: var(--color-grey);
//     border-radius: 10px;
//   }
// }

.help-swiper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: flex-start;

  .swiper-pagination {
    // position: absolute;
    // top: 0;
    // bottom: auto !important;
    // left: 10px !important;
    // right: 10px !important;
    // width: auto !important;

    .swiper-pagination-bullet-active {
      --swiper-theme-color: var(--color-app);
    }
  }
}

.circle-button {
  outline: none;
  background: none;
  appearance: none;
  border-radius: 0;
  border: none;

  width: 40px;
  height: 40px;
  background-color: var(--color-app);
  border-radius: 999px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
    display: block;

    path {
      fill: currentColor;
    }
  }
}

.help-slide__intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  .help-slide__logo {
    width: 60%;
  }

  .help-slide__image {
    width: 80%;
    margin-top: 20px;
  }

  p {
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
  }
}
