// Popup menu --------------------------------------------------
.popup {
  position: fixed;
  display: block;
  .popup__panel {
    position: absolute;

    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.3);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    bottom: 0;
    min-width: 100px;

    padding-top: 5px;
    padding-bottom: 5px;
    // transform: translateX(-50%);

    border-radius: 15px;
    opacity: 0;
    // to figure this out
    // transform: translate(-25%, -200%);
  }
}

.popup-menu {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    li {
      cursor: pointer;
      min-width: 166px;
      padding-top: 12px;
      padding-bottom: 15px;
      padding-left: 25px;
      padding-right: 25px;
      border-bottom: 1px solid var(--color-grey-200);
      white-space: nowrap;
      font-size: 16px;
      line-height: 1;
      text-align: center;
      // &:hover {
      //   background-color: grey;
      //   color: white;
      // }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.popup-color {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  .color-list {
    gap: 10px;
  }
  button {
    width: 30px !important;
    height: 30px !important;
  }
}
