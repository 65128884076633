.select-content {
  color: var(--color-app);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.select-content__header {
  flex-shrink: 0;
  border-bottom: 2px solid #f1f1f1;
}
.select-content__body {
  flex: 1;
  overflow: auto;
}
.select-content__footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
  padding-bottom: 30px;
  background-color: white;

  &:before {
    content: '';
    height: 100px;
    width: 100%;
    position: absolute;
    transform: translateY(-100%);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }
}

.select-content .theme-selection {
  margin-top: 0px;

  // scrollbar
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow: -moz-scrollbars-none; // Firefox
  scrollbar-width: none; /* Firefox 64 */

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.select-content .theme-selection {
  padding-left: var(--cnt-margin);
  padding-right: var(--cnt-margin);
}

.select-content__body {
  .swiper {
    padding-top: 75px;
    //   padding-bottom: 100px;
    //   height: 100%;
  }
  .swiper-wrapper {
    cursor: grab;
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: var(--cnt-margin);
    padding-right: var(--cnt-margin);
  }

  .swiper-pagination {
    margin-top: 25px;
  }
}

// -- Select content inside a overlay panel --------------------------------------------------------------- //

.overlay.overlay--panel {
  .select-content {
  }

  .select-content__header {
    padding-top: 0;
    padding-bottom: 15px;
  }

  // .select-content__body {
  //   .swiper {
  //     padding-top: 75px;
  //     //   padding-bottom: 100px;
  //     //   height: 100%;
  //   }
  //   .swiper-slide {
  //     display: flex;
  //     justify-content: center;
  //     flex-direction: column;
  //     padding-left: var(--cnt-margin);
  //     padding-right: var(--cnt-margin);
  //   }

  //   .swiper-pagination {
  //     margin-top: 25px;
  //   }
  // }

  // create some spacing for the place button
  .sentence-selection {
    margin-bottom: 200px;
  }
}

// -- Select content inside a fullscreen overlay --------------------------------------------------------------- //
.overlay.overlay--full {
  .select-content__header {
    padding-top: 50px;
    padding-bottom: 15px;
  }
}

// -- Sentence selection --------------------------------------------------------------- //

.sentence-selection {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 10px;

  font-size: 20px;
  line-height: 1.1;

  --label-color: #e8e8e8;
  --spac-vertical: 12px;

  margin-left: var(--spac-vertical);
  margin-right: var(--spac-vertical);
  li {
    display: block;
    margin-bottom: 1em;
  }
  span {
    cursor: pointer;
    display: inline;
    // padding: 0.5em 0em;
    padding-top: 5px;
    padding-bottom: 9px;
    background-color: var(--label-color);
    box-shadow: var(--spac-vertical) 0 0 var(--label-color), calc(var(--spac-vertical) * -1) 0 0 var(--label-color);
    box-decoration-break: clone;
    line-height: 1.45;
  }
}

.sentence-selection__tools {
  display: flex;
  justify-content: center;
  text-align: center;
  button {
    padding: 5px;
  }
}
.sentence-selection__item {
  user-select: none;
  &.is-active {
    --label-color: var(--color-app);

    span {
      background-color: var(--label-color);
      color: white;
    }
  }
}

// -- Illustration selection --------------------------------------------------------------- //

.illustration-selection {
  height: 100%;
  overflow: auto;
}
.illustration-selection__load {
  text-align: center;
  margin-bottom: 100px;

  button {
    background-color: black;
    color: white;
  }
}
//
.illustration-list {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  padding-left: var(--cnt-margin);
  padding-right: var(--cnt-margin);
}

.illustration-list__item {
  position: relative;
  cursor: pointer;

  .illustration-list__tile {
    position: relative;

    &.is-loading {
    }
  }

  .illustration-tile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid var(--color-grey-300);
    padding-top: var(--cnt-margin);
    padding-bottom: var(--cnt-margin);

    .illustration-tile__image {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      figure {
        outline: none;

        display: block;
        margin: 0;
        padding: 0;
        // border-radius: 5px;
        opacity: 0;
      }
      img {
        width: 100%;
        height: auto;
      }
      figure,
      img {
        display: block;
      }
    }

    .illustration-tile__loader {
      position: absolute;
      display: block;
      inset: 0;
      // background-color: red;
    }

    &.is-portrait {
      .illustration-tile__image {
        width: 60%;
      }
    }

    &.is-landscape {
      .illustration-tile__image {
        width: 90%;
      }
    }
  }
}

.illustration-tile__id {
  position: absolute;
  top: 0;
  left: 0;
}
// states
.illustration-tile .illustration-tile__image figure {
  opacity: 0;
  transition: opacity 250ms ease;
}
.illustration-tile.is-loaded {
  .illustration-tile__image figure {
    opacity: 1;
  }
}

.illustration-list__item.is-active {
  .illustration-tile {
    border-color: black;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 20px;
      height: 20px;
      background-color: black;
      border-radius: 999px;
      right: 10px;
      bottom: 10px;
    }
  }
}
