// // remove tap highlight
// * {
//   -webkit-tap-highlight-color: transparent;
// }

// /**
//    * Make all elements from the DOM inherit from the parent box-sizing
//    * Since `*` has a specificity of 0, it does not override the `html` value
//    * making all elements inheriting from the root box-sizing value
//    * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
//    */
// *,
// *:not(img),
// *:before,
// *:after {
//   box-sizing: inherit;
// }

// figure {
//   margin: 0;
// }

// ul {
//   padding: 0;
//   margin: 0;
//   list-style-type: none;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-weight: normal;
//   font-style: normal;
//   margin: 0;
//   font-size: 1em;
// }

// p {
//   margin: 0;
// }
//  might be to aggressive?

// ------------------------------------------------
// https://github.com/hankchizljaw/modern-css-reset
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* reset font weight */
th,
b,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 1em;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  // min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
