.generator__page {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  > * {
    flex: 1;
  }

  .generator__canvas {
    position: relative;
    flex-basis: 40vw;
    max-height: min(50vh, 90vw);
    overflow: visible;

    canvas {
      aspect-ratio: 1/1;
      width: 100%;
    }
  }

  .generator__interface {
    position: relative;

    flex: 1;
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background: #ffffff;
    box-shadow: 0 -2px 24px 0 rgba(0, 0, 0, 0.2);
    border-radius: 24px 24px 0 0;
    z-index: 2;
  }

  // act different on desktop
  @media (orientation: landscape) {
    // flex-basis: 400px;
    // max-height: 400px;

    .generator__canvas {
      flex: 1.3;
      flex-basis: 0;
    }
    .generator__interface {
      flex-basis: 0;
    }

    // flex: 1;
    // // aspect-ratio: 1/1;
    // // width: 100%;
    // max-height: none;
  }

  .generator__tools {
    flex-shrink: 0;
    display: flex;

    border-bottom: 1px solid var(--color-grey-300);
    min-height: 40px;
    // button {
    //   width: 40px;
    //   height: 40px;
    //   padding: 0;
    //   margin: 0;
    //   background: none;
    //   border: none;
    //   position: relative;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   // svg {
    //   //   position: absolute;
    //   //   width: 40px;
    //   //   height: 40px;
    //   // }
    // }

    ul {
    }
    .generator-toolbar {
      width: 100%;
      position: relative;
      height: 100%;
    }

    .generator-toolbar__progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      z-index: 2;
      bottom: -1px;

      pointer-events: none;
      border-bottom: 1px solid var(--color-app);
    }
    .generator-toolbar__progress__inner {
      position: absolute;

      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      bottom: -1px;

      transform-origin: left;
      opacity: 0.2;

      transform: scaleX(0%);

      background-color: var(--color-app);
    }

    .generator-toolbar__progress_sections {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      > span {
        position: relative;
        flex: 1;
        opacity: 1;
        // border: 1px solid red;
        &:after {
          content: '';
          width: 2px;
          display: block;
          color: var(--color-app);
          height: 5px;
          position: absolute;
          background-color: currentColor;
          right: 0;
          bottom: 0;
        }
        &:last-child:after {
          display: none;
        }
      }
    }

    .generator-toolbar__inner {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      color: var(--color-app);

      padding-left: 18px;
      padding-right: 17px;
      padding-top: 5px;
      padding-bottom: 2px;

      button {
        width: 45px;
        height: 45px;

        // border: 1px solid red;
        color: currentColor;

        &:first-child {
          margin-right: auto;
        }
      }
    }
  }

  .generator__body {
    flex: 1;
    position: relative;

    // min-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: contain;
    // margin-bottom: var(--toolbar-height);

    ul {
      display: block;
      padding: 0;
      margin: 0;
      li {
        width: 100%;
        height: 20px;
        border: 1px solid grey;
      }
    }
  }
}

.ok {
  position: absolute;
  width: 100%;
  height: 500px;
  border: 1px solid black;
  bottom: 0;
  left: 0;
}

.spacer {
  background-color: grey;
  height: 500px;
}

.sentence {
  --label-color: #e8e8e8;
  font-size: 20px;
  li {
    display: block;
  }
  .sentence__line {
    user-select: none;
    cursor: pointer;
    display: inline;
    padding: 0.5em 0em;
    background-color: var(--label-color);
    box-shadow: 0.5em 0 0 var(--label-color), -0.5em 0 0 var(--label-color);
    box-decoration-break: clone;
    line-height: 2.2;
  }

  // @keyframes easterEgg {
  //   from {
  //     transform: rotate(0deg);
  //   }
  //   to {
  //     transform: rotate(360deg);
  //   }
  // }

  &.is-active {
    .sentence__line {
      --label-color: black;
      color: white;
    }
  }
  // display: flex;
  // flex-direction: row;
}
.sentence__options {
  position: relative;
  &:first-child {
    margin-right: 1em;
  }
  &:last-child {
    margin-left: 1em;
  }
}

// -- Generator canvas --------- //@at-root

.generator-canvas-debug {
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  list-style: none;
  overflow: scroll;
  background-color: white;
  z-index: 3;
  width: 100%;
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;

  li {
    padding: 10px;
    border-bottom: 2px solid #d8d8d8;
    display: flex;
    align-items: center;
    gap: 10px;

    > div {
      flex: 1;
      > div {
        display: block;
        width: 100%;
      }
    }
  }

  figure {
    padding: 0;
    margin: 0;
    display: block;
    width: 50px;
    position: relative;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

canvas {
  width: 100%;
  // border: 1px solid grey;
}

// -- WIZARD ------------------------------------------------------------- //
.wizard {
}

.wizard__page {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;

  // padding-left: var(--cnt-margin);
  // padding-right: var(--cnt-margin);

  .wizard__header {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    h1 {
      @include fnt-heading-500();
      max-width: 316px;
    }
  }
  .wizard__section {
    text-align: center;
    margin-bottom: 50px;
    h3 {
      @include fnt-heading-100();
      margin-bottom: 1.2em;
    }
  }

  .wizard__start-method {
    hr {
      border: none;
      height: 2px;
      background-color: var(--color-grey);
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

// ------------------------------------------------------------------------- //
.canvas-loader {
  position: absolute;
  inset: 0;
  display: none;

  z-index: 1;
  display: none;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 0;
  background-color: var(--color-grey-100);
  pointer-events: none;
  transition: opacity 250ms ease;
  &.is-visible {
    display: flex;
    opacity: 1;
  }
}

// -- Illustration interface ---------------------------------------------------------- //
.illustration-editor {
  overflow: hidden;
  position: absolute;
  width: 100%;
  aspect-ratio: 1/0.9;
  inset: 0;
  z-index: 2;
  display: flex;
  justify-content: center;

  > div {
    // border: 1px solid red;
    position: absolute;
  }

  .illustration-editor__controls {
    background-color: white;
    display: flex;
    flex-direction: row;
    border-radius: 10px;

    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    margin-top: 10px;
    div {
      border-right: 1px solid var(--color-grey);
      padding: 5px;
    }
    button {
      color: var(--color-app);
    }

    button.is-active {
      color: red;
      color: var(--color-grey-500);
    }
  }
  .illustration-editor__layer {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    // border: 1px solid red;
    transition: top 250ms ease, left 250ms ease, width 250ms ease, height 250ms ease;
  }

  .illustration-editor__controller {
    width: 11px;
    height: 11px;
    background-color: white;
    border-radius: 999px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;

    button {
      flex-shrink: 0;
      position: relative;
      outline: none;
      background: none;
      appearance: none;
      border-radius: 0;
      border: none;
      color: currentColor;
      padding: 0;
      background-color: white;
      width: 40px;
      height: 40px;
      border-radius: 999px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);

      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        // border: 1px solid red;
      }

      &.control-up {
        transform: translateY(-40px);
      }
      &.control-down {
        transform: translateY(40px) rotate(180deg);
      }
      &.control-left {
        transform: translateX(-40px) rotate(-90deg);
      }

      &.control-right {
        transform: translateX(40px) rotate(90deg);
      }

      &.control-min {
        transform: translateX(-40px);
      }

      &.control-plus {
        transform: translateX(40px);
      }
    }
  }
}

// --- HTML Renderer ------------------------------------------------------------- //
.generator__canvas {
  canvas {
    display: none;
  }
}
.html-renderer {
  position: relative;
  aspect-ratio: 1/1;
  width: 100%;
  // border: 1px solid red;

  z-index: 2;
  overflow: hidden;
}

.html-layer {
  position: absolute;
  transition: top 250ms ease, left 250ms ease, width 250ms ease;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
