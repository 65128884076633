// Toolbar --------------------------------------------------
.main-button {
  position: relative;
  // button {
  //   width: 50px;
  //   height: 50px;
  //   border-radius: 999px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   // svg {
  //   //   display: block;
  //   //   width: 50px;
  //   //   height: 50px;
  //   // }
  // }
}

.toolbar-button {
  cursor: pointer;
  background: none;
  position: relative;
  border: none;
  color: black;
  transition: color 250ms ease;

  svg {
    polygon,
    path {
      fill: currentColor;
    }
  }

  &.is-active {
    color: var(--color-app);
  }
  // border: 1px solid red;
}

.toolbar {
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
  // position: fixed;
  // bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  display: block;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 14px;
  padding-right: 14px;
  > * {
    // border: 1px solid red;
    width: 33%;
    display: flex;
  }
  > *:nth-child(2) {
    text-align: center;
    justify-content: center;
  }
}
