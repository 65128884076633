.modal {
  position: fixed;
  inset: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.modal__header {
  flex-shrink: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: var(--cnt-margin);
  //   box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);

  height: 40px;

  h3 {
    margin: 0;
    font-size: 1.5em;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    width: 50px;
    height: 50px;

    svg {
      position: absolute;
      width: 40px;
      height: 40px;
    }
  }
}

.modal__body {
  position: relative;
  flex: 1;
  overflow-y: auto;
}

.modal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  button {
    // border: none;
    // background-color: black;
    // color: white;
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.4);
  }
}
