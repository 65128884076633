.icon-button {
  position: relative;
  outline: none;
  background: none;
  appearance: none;
  border-radius: 0;
  border: none;
  color: currentColor;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    position: relative;
    aspect-ratio: 1/1;

    rect,
    polygon,
    circle,
    path {
      fill: currentColor;
    }
  }
}
