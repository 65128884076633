.loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-grey-300);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

// .loader__spinner {
//   display: block;
//   background-color: black;
//   width: 30px;
//   height: 30px;
//   animation: spin 2s linear infinite;
// }

// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

.loader__container {
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 16px 0 rgba(167, 167, 167, 0.5);
  margin-bottom: 100px;

  path {
    fill: var(--color-app);
  }
}
