// $colors: (
//   'brown': (
//     #6e360f,
//     #7d4a27,
//     #8a5e3f,
//     #9a7357,
//     #a8866f
//   ),
//   'purple': (
//     #860efe,
//     #9e3efe,
//     #a955fe,
//     #b56dfe,
//     #c387ff
//   ),
//   'blue': (
//     #0026ff,
//     #3351ff,
//     #4d67ff,
//     #667dff,
//     #8093ff
//   ),
//   'orange': (
//     #ff7400,
//     #ff821a,
//     #ff9033,
//     #ff9e4d,
//     #ffac66
//   ),
//   'red': (
//     #ff0067,
//     #ff4d95,
//     #ff66a4,
//     #ff80b3,
//     #ff99c2
//   )
// );

$colors: (
  'brown': (
    #6e360f,
    #7d4a27,
    #8a5e3f,
    #9a7357,
    #a8866f
  ),
  'navy': (
    #0c4266,
    #245575,
    #3d6885,
    #557b94,
    #6d8ea3
  ),
  'ocean': (
    #048fb8,
    #36a5c6,
    #4fb1cd,
    #68bcd4,
    #82c7dc
  ),
  'purple': (
    #934fd6,
    #9e61da,
    #a972de,
    #b384e2,
    #be95e6
  ),
  'orange': (
    #e77107,
    #e97f20,
    #ec8d39,
    #ee9c51,
    #f1aa6a
  ),
  'red': (
    #eb005f,
    #ef337f,
    #f14d8f,
    #f3669f,
    #f580af
  ),
  'green': (
    #2bb043,
    #55c069,
    #6bc87b,
    #80d08e,
    #95d8a1
  )
);

:root {
  // margin
  --cnt-margin: 20px;

  // colors
  --color-grey: #d8d8d8;
  --color-grey-100: #f9f9f9;
  --color-grey-200: #efefef;
  --color-grey-300: #f1f1f1;
  --color-grey-500: #b9b9b9;

  --color-blue: #0026ff;

  --color-app: #0026ff;
  //sizes--toolbar-height: 66px;

  // ??
  --general-padding: 20px;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// -- Base Classes --------------------------------------------------- //

@mixin container {
  margin-left: var(--cnt-margin);
  margin-right: var(--cnt-margin);
}

@mixin container-reset {
  margin-left: 0;
  margin-right: 0;

  margin-left: calc(var(--cnt-margin) * -1);
  margin-right: calc(var(--cnt-margin) * -1);
}

.container {
  position: relative;
  @include container();
}

.container-reset {
  @include container-reset();
}

// .test {
//   height: 1000px;
//   border: 1px solid green;
//   width: 50vw;
// }

body {
  background: var(--ion-background-color);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  transform: translateZ(0);
  text-rendering: optimizeLegibility;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  word-wrap: break-word;
  overscroll-behavior-y: none;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}
// html,
// body {
//   position: fixed;
//   background-color: blue;
//   overflow: hidden;
//   -webkit-overflow-scrolling: touch;
// }
.lorem {
  // position: relative;
  // pointer-events: all;
  // border: 1px solid red;
  // height: 100vh;
  // padding-top: 20px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  overflow: hidden;
  z-index: 0;
  overscroll-behavior: none;

  .lorem__wrapper {
    position: relative;
    height: 300px;
    width: 100%;
    background-color: red;
    overflow-y: scroll;
    touch-action: pan-x pan-y pinch-zoom;
    overscroll-behavior: none;
    // -webkit-overflow-scrolling: touch;

    // scroll-behavior: contain;
  }

  ul > li {
    border: 1px solid green;
    height: 100px;
  }
}
